<template>
  <div>
    <chart-header header-name="statuses" />

    <div class="consultation-chart-container">
      <clip-loader v-if="loading" />

      <div v-if="!loading && !noData" class="chart">
        <div v-if="consultationsData.datasets" class="totalCount">
          <div>{{ trans("statistics.total") }}</div>
          <h5 v-if="totalConsultationsCount">
            {{ totalConsultationsCount }}
          </h5>
        </div>

        <doughnut-chart
          v-if="consultations"
          class="p-1 m-1 consultations-chart"
          :chartdata="consultationsData"
          :options="consultationsOptions"
        />
      </div>

      <div v-if="!loading && noData" class="text-center m-3">
        {{ trans("statistics.noResponseData") }}
      </div>
    </div>
  </div>
</template>

<script>
import DoughnutChart from "./ChartTypes/DoughnutChart.vue";
import ChartHeader from "./StatisticsUtils/ChartHeader.vue";

export default {
  components: {
    DoughnutChart,
    ChartHeader,
  },
  props: {
    consultations: {
      label: String,
      value: Number,
      percentage: Number,
    },
    loading: Boolean,
  },
  data: function () {
    return {
      noData: false,
      totalConsultationsCount: null,
      consultationsData: {
        labels: null,
        datasets: {
          data: null,
          backgroundColor: null,
          hoverOffset: null,
        },
      },
      consultationsOptions: {
        responsive: true,
        maintainAspectRatio: true,
        cutoutPercentage: 70,
        legend: {
          display: true,
          position: "bottom",
          labels: {
            boxWidth: 10,
            padding: 20,
            usePointStyle: true,
          },
        },
        tooltips: {
          titleFontColor: "rgba(0, 0, 0, 1)",
          bodyFontColor: "rgba(0, 0, 0, 1)",
          backgroundColor: "rgba(245, 245, 245, 1)",
          xPadding: 10,
          yPadding: 10,
          callbacks: {
            label: function (tooltipItem, data) {
              let label = data.labels[tooltipItem.index];
              let labelValue = data.datasets[0].data[tooltipItem.index];

              if (label) {
                try {
                  let splitLabelWithoutParenthesis = label.split(" (");
                  label = `${splitLabelWithoutParenthesis[0]}: ${labelValue}`;
                } catch {
                  return label;
                }
              }

              return label;
            },
          },
        },
      },
      backgroundColor: ["#4d7dbb", "#f2ad3c", "#00cea5"],
      hoverOffset: 4,
    };
  },
  watch: {
    consultations: function () {
      this.getData();
    },
  },
  created: function () {
    this.getData();
  },
  methods: {
    getData: function () {
      if (this.consultations) {
        let labels = Object.keys(this.consultations).map(
          (key) =>
            `${this.consultations[key].label} (${this.consultations[key].value})`,
        );

        let consultations = Object.keys(this.consultations).map(
          (key) => this.consultations[key].value,
        );

        let numberOfAnsweredExaminations = consultations.reduce(
          (a, b) => a + b,
          0,
        );

        this.totalConsultationsCount = numberOfAnsweredExaminations;

        if (numberOfAnsweredExaminations === 0) {
          this.noData = true;
        } else {
          this.noData = false;

          this.consultationsData = {
            labels,
            datasets: [
              {
                backgroundColor: this.backgroundColor,
                hoverOffset: this.hoverOffset,
                data: consultations,
              },
            ],
          };
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.totalCount {
  position: absolute;
  left: 50%;
  top: 37%;
  transform: translate(-50%, 0);
  text-align: center;
}

.consultations-chart {
  max-height: 350px;
}

@media (max-width: 820px) {
  .consultations-chart {
    max-height: 400px !important;
  }
}
</style>
