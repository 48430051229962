import App from "../../App.vue";
import AdminPatients from "../../components/Admin/AdminPatients.vue";
import Analytics from "../../components/Admin/Analytics.vue";
import ActiveCenters from "../../components/Admin/Centers/CentersList/ActiveCenters.vue";
import Centers from "../../components/Admin/Centers/CentersList/Centers.vue";
import InactiveCenters from "../../components/Admin/Centers/CentersList/InactiveCenters.vue";
import AdminCentersEdit from "../../components/Admin/Centers/Edit.vue";
import AdminEducationsEdit from "../../components/Admin/Educations/Edit.vue";
import Educations from "../../components/Admin/Educations/Educations.vue";
import New from "../../components/Admin/Educations/New.vue";
import AdminExport from "../../components/Admin/Export.vue";
import ApiInLog from "../../components/Admin/Logs/ApiInLog.vue";
import ApiOutLog from "../../components/Admin/Logs/ApiOutLog.vue";
import EmailLog from "../../components/Admin/Logs/EmailLog.vue";
import Log from "../../components/Admin/Logs/Log.vue";
import ExaminationTransferLog from "../../components/Admin/Logs/ExaminationTransferLog.vue";
import MinDoktorRetransfers from "../../components/Admin/Logs/MinDoktorRetransfers.vue";
import SystemLog from "../../components/Admin/Logs/SystemLog.vue";
import QuicksilvaLog from "../../components/Admin/Logs/QuicksilvaLog.vue";
import TakeCareLogs from "../../components/Admin/Logs/TakeCareLogs.vue";
import EditRegion from "../../components/Admin/Regions/EditRegion.vue";
import Regions from "../../components/Admin/Regions/Regions.vue";
import AdminUsersUserEdit from "../../components/Admin/Users/User/Edit.vue";
import NewUserInvite from "../../components/Admin/Users/User/NewUserInvite.vue";
import TentativeUserAdminActivate from "../../components/Admin/Users/User/TentativeUserAdminActivate.vue";
import ActiveUsersList from "../../components/Admin/Users/UsersList/ActiveUsersList.vue";
import InactiveUsersList from "../../components/Admin/Users/UsersList/InactiveUsersList.vue";
import PreliminaryUsersList from "../../components/Admin/Users/UsersList/PreliminaryUsersList.vue";
import UsersHeader from "../../components/Admin/Users/UsersList/UsersHeader.vue";
import Login from "../../components/Auth/Login.vue";
import Logout from "../../components/Auth/Logout.vue";
import Reset from "../../components/Auth/Reset.vue";
import AuthError from "../../components/Auth/AuthError.vue";
import SvenskEidentitet from "../../components/Auth/SvenskEidentitet.vue";
import SvenskEidentitetVerify from "../../components/Auth/SvenskEidentitetVerify.vue";
import Siths from "../../components/Auth/Siths.vue";
import SithsVerify from "../../components/Auth/SithsVerify.vue";
import TakeCareVerify from "../../components/Auth/TakeCareVerify.vue";
import Sms from "../../components/Auth/Sms.vue";
import Verify from "../../components/Auth/Verify.vue";
import CenterHeader from "../../components/Center/CenterHeader.vue";
import Export from "../../components/Center/Exports/Export.vue";
import Edit from "../../components/Center/Groups/Edit.vue";
import Groups from "../../components/Center/Groups/Groups.vue";
import CenterPatients from "../../components/Center/Patients/CenterPatients.vue";
import Mole from "../../components/Educations/EducationCase/Mole.vue";
import EducationsList from "../../components/Educations/EducationsList.vue";
import Errors404 from "../../components/errors/404.vue";
import BrowserNotSupported from "../../components/errors/BrowserNotSupported.vue";
import List from "../../components/Evaluations/List.vue";
import ExaminationContainer from "../../components/Examinations/Examination/ExaminationContainer.vue";
import Wound from "../../components/Examinations/Wound/Wound.vue";
import PatientRoute from "../../components/Patients/PatientRoute.vue";
import NoAccessToPatientFromTakeCare from "../../components/Patients/NoAccessToPatientFromTakeCare.vue";
import PatientOverview from "../../components/Patients/PatientOverview.vue";
import PatientImageCompare from "../../components/Patients/PatientImageCompare.vue";
import RelatedExaminations from "../../components/Patients/RelatedExaminations.vue";
import Patients from "../../components/Patients/Patients.vue";
import RegionCenters from "../../components/Region/RegionCenters.vue";
import RegionExport from "../../components/Region/RegionExport.vue";
import RegionHeader from "../../components/Region/RegionHeader.vue";
import NoRegion from "../../components/Region/NoRegion.vue";
import RegionNewCenter from "../../components/Region/RegionNewCenter.vue";
import RegionSettings from "../../components/Region/RegionSettings.vue";
import RegionPatients from "../../components/Region/RegionPatients.vue";
import RegistrationForm from "../../components/Registration/RegistrationForm.vue";
import Statistics from "../../components/Statistics/Statistics.vue";
import Contact from "../../components/Support/Contact.vue";
import RegionSupportPage from "../../components/Support/RegionsSupport/RegionSupportPage.vue";
import User from "../../components/User/User.vue";
import { research } from "../research";
import { center } from "../center";
import { region } from "../region";
import { researchAdmin } from "../research-admin";
import { RouterView } from "vue-router";
import ExaminationHome from "../../components/Examinations/Examination/ExaminationComponents/components/ExaminationHome.vue";
import ExaminationImageCompare from "../../components/Examinations/Examination/ExaminationComponents/components/ExaminationImageCompare.vue";

/**
 * Practitioner portal module.
 *
 * @type {import("../routing/app-module").AppModule}
 */
export const practitionerPortal = {
  routes: [
    {
      path: "/",
      component: App,
      children: [
        {
          path: "/auth",
          component: Login,
          name: "Auth",
          meta: {
            disallowAuthed: true,
          },
        },
        {
          path: "/auth/logout",
          component: Logout,
          name: "Logout",
        },
        {
          path: "/auth/sms",
          component: Sms,
          name: "SmsLogin",
        },
        {
          path: "/auth/password/reset",
          component: Reset,
          name: "ResetLogin",
        },
        {
          path: "/auth/password/reset/:token",
          component: Reset,
          name: "ResetLoginStep2",
        },
        {
          path: "/auth/verification/verify/:id/:hash",
          component: Verify,
          name: "Verify",
        },
        {
          path: "/auth/siths",
          component: Siths,
          name: "Siths",
        },
        {
          path: "/auth/siths/verify",
          component: SithsVerify,
          name: "SithsVerify",
        },
        {
          path: "/auth/svensk-e-identitet/:customer",
          component: SvenskEidentitet,
          name: "SvenskEidentitet",
        },
        {
          path: "/auth/svensk-e-identitet/verify/:customer",
          component: SvenskEidentitetVerify,
          name: "SvenskEidentitetVerify",
        },
        {
          path: "/auth/takecare/verify",
          component: TakeCareVerify,
          name: "TakeCareVerify",
        },
        {
          path: "/auth/error",
          component: AuthError,
          name: "AuthError",
        },
        {
          path: "/consultations",
          component: RouterView,
          meta: {
            requiresAuth: true,
          },
          props: true,
          children: [
            {
              path: "",
              name: "Evaluation",
              component: List,
            },
            {
              path: "/mole/:id/edit",
              redirect: { name: "Mole" },
            },
            {
              path: "/consultations/mole/:id/edit",
              component: ExaminationContainer,
              name: "Mole",
              meta: {
                requiresAuth: true,
              },
              children: [
                {
                  path: "",
                  component: ExaminationHome,
                  name: "MoleHome",
                  props: true,
                },
                {
                  path: "images",
                  component: ExaminationImageCompare,
                  name: "MoleImages",
                  props: true,
                },
              ],
            },
            {
              path: "/wound-legacy/:id/edit",
              redirect: { name: "WoundLegacy" },
            },
            {
              path: "/consultations/wound-legacy/:id/edit",
              component: Wound,
              name: "WoundLegacy",
              meta: {
                requiresAuth: true,
              },
              children: [
                {
                  path: "",
                  component: ExaminationHome,
                  name: "WoundLegacyHome",
                  props: true,
                },
                {
                  path: "images",
                  component: ExaminationImageCompare,
                  name: "WoundLegacyImages",
                  props: true,
                },
              ],
            },
            {
              path: "/mole-uk/:id/edit",
              redirect: { name: "MoleUk" },
            },
            {
              path: "/consultations/mole-uk/:id/edit",
              component: ExaminationContainer,
              name: "MoleUk",
              meta: {
                requiresAuth: true,
              },
              children: [
                {
                  path: "",
                  component: ExaminationHome,
                  name: "MoleUkHome",
                  props: true,
                },
                {
                  path: "images",
                  component: ExaminationImageCompare,
                  name: "MoleUkImages",
                  props: true,
                },
              ],
            },
            {
              path: "/wound/:id/edit",
              redirect: { name: "Wound" },
            },
            {
              path: "/consultations/wound/:id/edit",
              component: ExaminationContainer,
              name: "Wound",
              meta: {
                requiresAuth: true,
              },
              children: [
                {
                  path: "",
                  component: ExaminationHome,
                  name: "WoundHome",
                  props: true,
                },
                {
                  path: "images",
                  component: ExaminationImageCompare,
                  name: "WoundImages",
                  props: true,
                },
              ],
            },
            {
              path: "/tumour-wound/:id/edit",
              redirect: { name: "TumourWound" },
            },
            {
              path: "/consultations/tumour-wound/:id/edit",
              component: ExaminationContainer,
              name: "TumourWound",
              meta: {
                requiresAuth: true,
              },
              children: [
                {
                  path: "",
                  component: ExaminationHome,
                  name: "TumourWoundHome",
                  props: true,
                },
                {
                  path: "images",
                  component: ExaminationImageCompare,
                  name: "TumourWoundImages",
                  props: true,
                },
              ],
            },
            {
              path: "/region-skane-mole/:id/edit",
              redirect: { name: "RegionSkaneMole" },
            },
            {
              path: "/consultations/region-skane-mole/:id/edit",
              component: ExaminationContainer,
              name: "RegionSkaneMole",
              meta: {
                requiresAuth: true,
              },
              children: [
                {
                  path: "",
                  component: ExaminationHome,
                  name: "RegionSkaneMoleHome",
                  props: true,
                },
                {
                  path: "images",
                  component: ExaminationImageCompare,
                  name: "RegionSkaneMoleImages",
                  props: true,
                },
              ],
            },
            {
              path: "/wound-uk/:id/edit",
              redirect: { name: "WoundUK" },
            },
            {
              path: "/consultations/wound-uk/:id/edit",
              component: ExaminationContainer,
              name: "WoundUK",
              meta: {
                requiresAuth: true,
              },
              children: [
                {
                  path: "",
                  component: ExaminationHome,
                  name: "WoundUKHome",
                  props: true,
                },
                {
                  path: "images",
                  component: ExaminationImageCompare,
                  name: "WoundUKImages",
                  props: true,
                },
              ],
            },
            {
              path: "/photo/:id/edit",
              redirect: { name: "Photo" },
            },
            {
              path: "/consultations/photo/:id/edit",
              component: ExaminationContainer,
              name: "Photo",
              meta: {
                requiresAuth: true,
              },
              children: [
                {
                  path: "",
                  component: ExaminationHome,
                  name: "PhotoHome",
                  props: true,
                },
                {
                  path: "images",
                  component: ExaminationImageCompare,
                  name: "PhotoImages",
                  props: true,
                },
              ],
            },
            {
              path: "/photo-uk/:id/edit",
              redirect: { name: "Photo UK" },
            },
            {
              path: "/consultations/photo-uk/:id/edit",
              component: ExaminationContainer,
              name: "Photo UK",
              meta: {
                requiresAuth: true,
              },
              children: [
                {
                  path: "",
                  component: ExaminationHome,
                  name: "PhotoUKHome",
                  props: true,
                },
                {
                  path: "images",
                  component: ExaminationImageCompare,
                  name: "PhotoUKImages",
                  props: true,
                },
              ],
            },
            {
              path: "/consultations/wound-lite/:id/edit",
              component: ExaminationContainer,
              name: "WoundLite",
              meta: {
                requiresAuth: true,
              },
              children: [
                {
                  path: "",
                  component: ExaminationHome,
                  name: "WoundLiteHome",
                  props: true,
                },
                {
                  path: "images",
                  component: ExaminationImageCompare,
                  name: "WoundLiteImages",
                  props: true,
                },
              ],
            },
          ],
        },
        {
          path: "/",
          redirect: "/consultations",
        },
        {
          path: "/patients",
          component: RouterView,
          meta: {
            requiresAuth: true,
          },
          children: [
            {
              path: "",
              component: Patients,
              name: "Patients",
            },
            {
              path: "/patients/:id/edit",
              redirect: (to) => ({
                name: "PatientOverview",
                params: { id: to.params.id },
              }),
              meta: {
                requiresAuth: true,
              },
            },
            {
              path: "/patients/no-access-take-care",
              component: NoAccessToPatientFromTakeCare,
              meta: {
                requiresAuth: true,
              },
            },
            {
              path: "/patients/:id",
              component: PatientRoute,
              name: "Patient",
              meta: {
                requiresAuth: true,
                // keepAlive: true,
              },
              children: [
                {
                  path: "",
                  component: PatientOverview,
                  name: "PatientOverview",
                  meta: {
                    requiresAuth: true,
                  },
                },
                {
                  path: "images",
                  component: PatientImageCompare,
                  name: "PatientImages",
                  meta: {
                    requiresAuth: true,
                  },
                },
                {
                  path: "related-examinations",
                  component: RelatedExaminations,
                  name: "PatientRelatedExaminations",
                  meta: {
                    requiresAuth: true,
                  },
                },
              ],
            },
          ],
        },
        {
          path: "/patients/:id/consensus",
          redirect: (to) => {
            return {
              name: "Patient",
              params: { id: to.params.id },
            };
          },
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/support",
          component: RouterView,
          meta: {
            requiresAuth: true,
          },
          children: [
            {
              path: "",
              component: RegionSupportPage,
              name: "SupportDocumentation",
            },
            {
              path: "/support/region-support",
              component: RegionSupportPage,
              name: "RegionSupportPage",
              meta: {
                requiresAuth: true,
              },
            },
            {
              path: "/support/contact",
              component: Contact,
              name: "SupportContact",
              meta: {
                requiresAuth: true,
              },
            },
          ],
        },
        {
          path: "/educations",
          meta: {
            requiresAuth: true,
          },
          children: [
            { path: "", component: EducationsList, name: "Educations" },
            {
              path: "/educations/moles/:id/edit",
              component: Mole,
              name: "EducationMole",
              meta: {
                requiresAuth: true,
              },
            },
            {
              path: "/educations/mole/:id/edit",
              component: Mole,
              name: "EducationMole",
              meta: {
                requiresAuth: true,
              },
            },
          ],
        },
        {
          path: "/statistics",
          component: Statistics,
          name: "Statistics",
          meta: {
            requiresAuth: true,
          },
        },
        ...researchAdmin.routes,
        ...research.routes,
        {
          path: "/auth/settings",
          component: User,
          name: "UserSettings",
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/center",
          component: CenterHeader,
          name: "CenterHeader",
          redirect: "/center/settings",
          meta: {
            requiresAuth: true,
            centerAuth: true,
          },
        },
        ...center.routes,
        {
          path: "/center/export",
          component: Export,
          name: "CenterExport",
          meta: {
            requiresAuth: true,
            centerAuth: true,
          },
        },
        {
          path: "/center/groups",
          component: Groups,
          name: "CenterGroups",
          meta: {
            requiresAuth: true,
            centerAuth: true,
          },
        },
        {
          path: "/center/patients",
          component: CenterPatients,
          name: "CenterPatients",
          meta: {
            requiresAuth: true,
            centerAuth: true,
          },
        },
        {
          path: "/center/groups/edit/:groupId",
          component: Edit,
          name: "EditCenterGroup",
          meta: {
            requiresAuth: true,
            centerAuth: true,
          },
        },
        {
          path: "/center/groups/edit/new",
          name: "NewCenterGroup",
          redirect: () => {
            return {
              name: "EditCenterGroup",
              params: { groupId: "new" },
            };
          },
        },
        {
          path: "/region/no-region",
          component: NoRegion,
          name: "NoRegion",
          meta: {
            requiresAuth: true,
            regionAuth: true,
          },
        },
        {
          path: "/region",
          component: RegionHeader,
          name: "RegionHeader",
          redirect: "/region/settings",
          meta: {
            requiresAuth: true,
            regionAuth: true,
            breadcrumb: "breadcrumbs.region",
          },
          children: [
            {
              path: "/region/settings",
              component: RegionSettings,
              name: "RegionSettings",
              meta: {
                requiresAuth: true,
                regionAuth: true,
                breadcrumb: "breadcrumbs.regionSettings",
              },
            },
            {
              path: "/region/export",
              component: RegionExport,
              name: "RegionExport",
              meta: {
                requiresAuth: true,
                regionAuth: true,
                breadcrumb: "breadcrumbs.regionExport",
              },
            },
            {
              path: "/region/patients",
              component: RegionPatients,
              name: "RegionPatients",
              meta: {
                requiresAuth: true,
                regionAuth: true,
                breadcrumb: "breadcrumbs.regionPatients",
              },
            },
            {
              path: "/region/centers",
              component: RegionCenters,
              name: "RegionCenters",
              meta: {
                requiresAuth: true,
                regionAuth: true,
                breadcrumb: "breadcrumbs.regionCenters",
              },
              children: [
                {
                  path: "/region/centers/newCentre",
                  component: RegionNewCenter,
                  name: "RegionNewCenter",
                  meta: {
                    requiresAuth: true,
                    regionAuth: true,
                    breadcrumb: "breadcrumbs.regionNewCenter",
                  },
                },
                {
                  path: "/region/centers/:id",
                  redirect: (to) => {
                    return {
                      name: "regionCenterSettings",
                      params: { id: to.params.id },
                    };
                  },
                  meta: {
                    requiresAuth: true,
                    regionAuth: true,
                  },
                },
                ...region.routes,
                {
                  path: "/region/centers/:id/groups",
                  component: Groups,
                  name: "RegionCenterGroups",
                  meta: {
                    requiresAuth: true,
                    regionAuth: true,
                    breadcrumb: "breadcrumbs.regionEditCenterGroups",
                  },
                },
                {
                  path: "/region/centers/:id/groups/edit/:groupId",
                  component: Edit,
                  name: "EditRegionCenterGroup",
                  meta: {
                    requiresAuth: true,
                    regionAuth: true,
                    breadcrumb: "breadcrumbs.regionEditCenterGroup",
                  },
                },
                {
                  path: "/region/centers/:id/groups/new",
                  component: Edit,
                  name: "NewRegionCenterGroup",
                  meta: {
                    requiresAuth: true,
                    regionAuth: true,
                    breadcrumb: "breadcrumbs.regionEditCenterGroup",
                  },
                },
              ],
            },
          ],
        },
        {
          path: "/region/centers/new",
          component: AdminCentersEdit,
          name: "RegionCentersNew",
          meta: {
            requiresAuth: true,
            adminAuth: true,
          },
        },
        {
          path: "/admin",
          component: Centers,
          name: "Admin",
          redirect: "/admin/centers/active-centers",
          meta: {
            requiresAuth: true,
            adminAuth: true,
          },
        },
        {
          path: "/admin/centers",
          component: Centers,
          name: "AdminCenters",
          redirect: "/admin/centers/active-centers",
          meta: {
            requiresAuth: true,
            adminAuth: true,
          },
          children: [
            {
              path: "active-centers",
              component: ActiveCenters,
              name: "AdminCentersActiveCenters",
              meta: {
                requiresAuth: true,
                adminAuth: true,
              },
            },
            {
              path: "inactive-centers",
              component: InactiveCenters,
              name: "AdminCentersInactiveCenters",
              meta: {
                requiresAuth: true,
                adminAuth: true,
              },
            },
          ],
        },
        {
          path: "/admin/centers/:id",
          component: AdminCentersEdit,
          name: "AdminCentersEdit",
          meta: {
            requiresAuth: true,
            adminAuth: true,
          },
        },
        {
          path: "/admin/regions",
          component: Regions,
          name: "AdminRegions",
          meta: {
            requiresAuth: true,
            adminAuth: true,
          },
        },
        {
          path: "/admin/regions/:id",
          component: EditRegion,
          name: "AdminRegionsEdit",
          meta: {
            requiresAuth: true,
            adminAuth: true,
          },
        },
        {
          path: "/admin/users",
          component: UsersHeader,
          name: "AdminUsers",
          meta: {
            requiresAuth: true,
            adminAuth: true,
          },
          redirect: "/admin/users/active-users",
          children: [
            {
              path: "active-users",
              component: ActiveUsersList,
              name: "AdminUsersActiveUsers",
              meta: {
                requiresAuth: true,
                adminAuth: true,
              },
            },
            {
              path: "inactive-users",
              component: InactiveUsersList,
              name: "AdminUsersInactiveUsers",
              meta: {
                requiresAuth: true,
                adminAuth: true,
              },
            },
            {
              path: "/admin/users/preliminary-users",
              component: PreliminaryUsersList,
              name: "AdminUsersPreliminary",
              meta: {
                requiresAuth: true,
                adminAuth: true,
              },
            },
          ],
        },
        {
          path: "/admin/users/invite",
          component: NewUserInvite,
          name: "NewUserInvite",
          meta: {
            requiresAuth: true,
            adminAuth: true,
          },
        },
        {
          path: "/admin/users/:id",
          component: AdminUsersUserEdit,
          name: "AdminUsersEdit",
          meta: {
            requiresAuth: true,
            adminAuth: true,
          },
        },
        {
          path: "/admin/preliminary/users/:id",
          component: TentativeUserAdminActivate,
          name: "AdminPreliminaryUsersActivate",
          meta: {
            requiresAuth: true,
            adminAuth: true,
          },
        },
        {
          path: "/admin/educations",
          component: Educations,
          name: "AdminEducations",
          meta: {
            requiresAuth: true,
            adminAuth: true,
          },
        },
        {
          path: "/admin/educations/new",
          component: New,
          name: "AdminEducationsNew",
          meta: {
            requiresAuth: true,
            adminAuth: true,
          },
        },
        {
          path: "/admin/educations/:id",
          component: AdminEducationsEdit,
          name: "AdminEducationsEdit",
          meta: {
            requiresAuth: true,
            adminAuth: true,
          },
        },
        {
          path: "/admin/logs",
          component: Log,
          name: "AdminLog",
          meta: {
            requiresAuth: true,
            adminAuth: true,
          },
          redirect: "/admin/logs/system",
          children: [
            {
              path: "system",
              component: SystemLog,
              meta: {
                requiresAuth: true,
                adminAuth: true,
              },
            },
            {
              path: "apiOut",
              component: ApiOutLog,
              meta: {
                requiresAuth: true,
                adminAuth: true,
              },
            },
            {
              path: "apiIn",
              component: ApiInLog,
              meta: {
                requiresAuth: true,
                adminAuth: true,
              },
            },
            {
              path: "email",
              component: EmailLog,
              meta: {
                requiresAuth: true,
                adminAuth: true,
              },
            },
            {
              path: "minDoktorRetransfers",
              component: MinDoktorRetransfers,
              meta: {
                requiresAuth: true,
                adminAuth: true,
              },
            },
            {
              path: "quicksilva",
              component: QuicksilvaLog,
              meta: {
                requiresAuth: true,
                adminAuth: true,
              },
            },
            {
              path: "examinationTransfers",
              component: ExaminationTransferLog,
              meta: {
                requiresAuth: true,
                adminAuth: true,
              },
            },
            {
              path: "takeCare",
              component: TakeCareLogs,
              meta: {
                requiresAuth: true,
                adminAuth: true,
              },
            },
          ],
        },
        {
          path: "/admin/patients",
          component: AdminPatients,
          name: "AdminPatients",
          meta: {
            requiresAuth: true,
            adminAuth: true,
          },
        },
        {
          path: "/admin/analytics",
          component: Analytics,
          name: "AdminAnalytics",
          meta: {
            requiresAuth: true,
            adminAuth: true,
          },
        },
        {
          path: "/admin/exports",
          component: AdminExport,
          name: "AdminExport",
          meta: {
            requiresAuth: true,
            adminAuth: true,
          },
        },
        {
          path: "/unsupported-browser",
          component: BrowserNotSupported,
          name: "BrowserNotSupported",
          meta: {
            requiresAuth: false,
          },
        },
        {
          path: "/register/:key",
          component: RegistrationForm,
          name: "RegistrationForm",
          meta: {
            requiresAuth: false,
          },
        },
        {
          path: "/:pathMatch(.*)*",
          component: Errors404,
          name: "NotFoundPage",
          requiresAuth: false,
        },
      ],
    },
  ],
};
