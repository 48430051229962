<template>
  <ul class="nav nav-tabs tabs-bordered">
    <li class="nav-item">
      <a
        href="#profile"
        data-toggle="tab"
        aria-expanded="true"
        class="nav-link"
        :class="{ active: activeTab === 'profile' }"
        @click="setActiveTab('profile')"
        >{{ trans("users.tabMenuLabelProfile") }}</a
      >
    </li>
    <li class="nav-item">
      <a
        href="#settings"
        data-toggle="tab"
        aria-expanded="false"
        class="nav-link"
        :class="[
          componentPurpose === 'admin' ? 'settingsLink' : '',
          { active: activeTab === 'settings' },
        ]"
        @click="setActiveTab('settings')"
        >{{ trans("users.tabMenuLabelSettings") }}</a
      >
    </li>
    <li class="nav-item">
      <a
        href="#products"
        data-toggle="tab"
        aria-expanded="false"
        class="nav-link"
        :class="{ active: activeTab === 'products' }"
        @click="setActiveTab('products')"
        >{{ trans("users.tabMenuLabelProducts") }}</a
      >
    </li>
    <li v-if="componentPurpose === 'userSettings'" class="nav-item">
      <a
        href="#twofa"
        data-toggle="tab"
        aria-expanded="false"
        class="nav-link"
        :class="{ active: activeTab === 'twofa' }"
        @click="setActiveTab('twofa')"
        >{{ trans("users.tabMenuLabelTwoFactorAuthentication") }}</a
      >
    </li>
    <li v-if="componentPurpose === 'userSettings'" class="nav-item">
      <a
        href="#history"
        data-toggle="tab"
        aria-expanded="false"
        class="nav-link"
        :class="{ active: activeTab === 'history' }"
        @click="setActiveTab('history')"
        >{{ trans("users.historicalHeader") }}</a
      >
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    componentPurpose: String,
    activeTab: String,
  },
  methods: {
    setActiveTab(tab) {
      this.$emit("update:activeTab", tab);
    },
  },
};
</script>
