<template>
  <div class="row">
    <div class="col-md-12">
      <div class="panel panel-default panel-fill">
        <div class="panel-heading">
          <h3 class="panel-title">
            {{ trans("users.tabMenuLabelTwoFactorAuthentication") }}
          </h3>
        </div>
        <div class="panel-body">
          <div
            v-if="user.google2fa_secret_verified && !verificationSuccess"
            class="alert alert-info"
          >
            {{ trans("auth.authAppConfiguredMessage") }}
            <br />
            <button
              class="btn btn-warning btn-sm m-t-10"
              @click="reconfigureAuthApp"
            >
              {{ trans("auth.authAppRemoveReconfigureButtonText") }}
            </button>
          </div>
          <div v-else>
            <div v-if="!verificationSuccess" class="alert alert-info">
              <p>{{ trans("auth.authAppSetupInfoBox") }}</p>
              <p>{{ trans("auth.authAppSetupInfoBoxExplanation") }}</p>
            </div>

            <div v-if="qrCode && !verificationSuccess">
              <img :src="qrCode" class="qr-code-img" />

              <p>{{ trans("auth.authAppEnterCodeToVerify") }}</p>

              <div v-if="verificationError" class="alert alert-danger m-t-10">
                {{ verificationError }}
              </div>

              <input
                v-model="verificationCode"
                :placeholder="trans('auth.authAppEnterCodePlaceholder')"
                type="text"
                class="form-control m-t-10 w-50 text-center"
                maxlength="6"
              />
              <button class="btn btn-success m-t-10" @click="verifyAuthApp">
                {{ trans("auth.authAppVerify") }}
              </button>
            </div>

            <div v-if="verificationSuccess" class="alert alert-success m-t-10">
              {{ verificationSuccess }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { practitionerHttpService } from "../../app/practitioner-portal/store/practitioner-http-service";

export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      qrCode: null,
      verificationCode: "",
      verificationError: null,
      verificationSuccess: null,
    };
  },
  mounted() {
    if (!this.user.google2fa_secret_verified) {
      this.generateQrCode();
    }
  },
  methods: {
    generateQrCode() {
      practitionerHttpService
        .get("/api/internal/auth/auth-app-qr")
        .then((response) => {
          this.qrCode = response.data.qr;
        });
    },
    verifyAuthApp() {
      this.verificationError = null;
      practitionerHttpService
        .post("/api/internal/auth/auth-app-verify", {
          code: this.verificationCode,
        })
        .then(() => {
          this.$emit("verificationSuccess");
          this.verificationSuccess = this.trans(
            "auth.authAppVerificationSuccess",
          );
        })
        .catch((error) => {
          this.verificationError = this.trans("auth.authAppVerificationFailed");
        });
    },
    reconfigureAuthApp() {
      practitionerHttpService
        .delete("/api/internal/auth/remove-auth-app")
        .then(() => {
          this.user.google2fa_secret_verified = false;
          this.qrCode = null;
          this.generateQrCode();
        });
    },
  },
};
</script>
