<template>
  <examination :product="product" :examination="examination" />
  <ExaminationStudyConsents
    :data-examination-id="examination.id"
    :data-primary-center-id="primaryCenterId"
    :data-patient-phone="examination.patient.telephone_number"
  />
</template>

<script>
import Examination from "../../Examination.vue";
import ExaminationStudyConsents from "../ExaminationStudyConsents.vue";

export default {
  components: {
    Examination,
    ExaminationStudyConsents,
  },
  props: {
    product: Object,
    examination: Object,
    primaryCenterId: Number,
  },
};
</script>
