<template>
  <div>
    <chart-header header-name="responseTrackerHeader" />

    <div class="response-tracker-container">
      <clip-loader v-if="loading" />

      <div v-if="!loading && !noData" class="chart">
        <div v-if="responseTrackerData.datasets" class="answeredCount">
          <div>{{ trans("statistics.total") }}</div>
          <h5>
            {{
              responseTrackerData.datasets[0].data[0] +
              responseTrackerData.datasets[0].data[1]
            }}
          </h5>
        </div>
        <doughnut-chart
          v-if="responseTrackerData.datasets"
          class="p-1 m-1 response-tracker-chart"
          :chartdata="responseTrackerData"
          :options="responseTrackerOptions"
        />
        <div v-if="responseTrackerData.datasets" class="unansweredCount">
          <div>&nbsp;</div>
          <h5>&nbsp;</h5>
        </div>
      </div>

      <div v-if="!loading && noData" class="text-center m-3">
        {{ trans("statistics.noResponseData") }}
      </div>
    </div>
  </div>
</template>

<script>
import DoughnutChart from "./ChartTypes/DoughnutChart.vue";
import ChartHeader from "./StatisticsUtils/ChartHeader.vue";

export default {
  components: {
    DoughnutChart,
    ChartHeader,
  },
  props: {
    responseData: {
      responseTimes: {
        avgResponseTime: Number,
        numberOfAnsweredExaminations: Number,
      },
      statuses: {
        label: String,
        percentage: Number,
        value: Number,
      },
    },
    loading: Boolean,
  },

  data: function () {
    return {
      noData: false,
      responseTrackerData: {
        labels: [
          this.trans("statistics.asweredConsultationsLabel"),
          this.trans("statistics.unasweredConsultationsLabel"),
        ],
        datasets: null,
      },
      responseTrackerOptions: {
        responsive: true,
        maintainAspectRatio: true,
        cutoutPercentage: 70,
        legend: {
          display: false,
        },
        tooltips: {
          titleFontColor: "rgba(0, 0, 0, 1)",
          bodyFontColor: "rgba(0, 0, 0, 1)",
          backgroundColor: "rgba(245, 245, 245, 1)",
          xPadding: 10,
          yPadding: 10,
        },
      },
      backgroundColor: ["#4d7dbb", "#e7e7e7"],
      borderColor: ["#4d7dbb", "#e7e7e7"],
    };
  },
  watch: {
    responseData: function () {
      this.getData();
    },
  },
  methods: {
    getData: function () {
      if (this.responseData) {
        let consultationsData = this.responseData.statuses;

        let allConsultations = Object.keys(consultationsData).map(
          (key) => consultationsData[key].value,
        );

        let answered =
          this.responseData.responseTimes.numberOfAnsweredExaminations;

        let unanswered =
          this.responseData.responseTimes.numberOfUnansweredExaminations;

        if (answered === 0 && unanswered === 0) {
          this.noData = true;
        } else {
          this.noData = false;
          this.responseTrackerData.labels = [
            this.trans("statistics.asweredConsultationsLabel") +
              ` (${answered})`,
            this.trans("statistics.unansweredClosedConsultationsLabel") +
              ` (${unanswered})`,
          ];
          this.responseTrackerData.datasets = [
            {
              data: [answered, unanswered],
              backgroundColor: this.backgroundColor,
              borderColor: this.borderColor,
            },
          ];
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.answeredCount {
  position: absolute;
  left: 50%;
  top: 35%;
  transform: translate(-50%, 0);
  text-align: center;
}

.unansweredCount {
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
  text-align: center;

  h5 {
    color: #b92844;
  }
}

.response-tracker-chart {
  max-height: 200px;
}

@media (max-width: 820px) {
  .response-tracker-chart {
    max-height: 350px;
  }
}
</style>
