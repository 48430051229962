<template>
  <div>
    <header-admin :active-section="7" />

    <div class="tab-content">
      <clip-loader v-if="isLoading" />
      <Panel
        v-else
        :title="trans('forms.dermicusExportsLabel')"
        class="position-relative"
      >
        <clip-loader v-if="isExporting" />
        <div v-show="!isExporting">
          <ExportView
            :data-available-exports="availableExports"
            :data-form-errors="errors"
            @export-data="exportCsv"
          />
        </div>
      </Panel>
    </div>
  </div>
</template>

<script>
import _has from "lodash/fp/has";
import _filter from "lodash/fp/filter";
import contentDisposition from "content-disposition";
import HeaderAdmin from "./Header.vue";
import Panel from "../Layout/Panel.vue";
import ExportView from "../../app/practitioner-portal/generic/export/ExportView.vue";
import ExportWithoutOptionsForm from "../../app/practitioner-portal/generic/export/ExportWithoutOptionsForm.vue";
import ExportPatientLogForm from "../../app/practitioner-portal/generic/export/ExportPatientLogForm.vue";
import ExportUserLogForm from "../../app/practitioner-portal/generic/export/ExportUserLogForm.vue";
import ExportConsultAnswersForm from "../../app/practitioner-portal/admin/ExportConsultAnswersForm.vue";
import ExportDateFiltersOnlyForm from "../../app/practitioner-portal/generic/export/ExportDateFiltersOnlyForm.vue";
import ExportExaminationsForm from "../../app/practitioner-portal/generic/export/ExportExaminationsForm.vue";
import ExportAiForm from "../../app/practitioner-portal/admin/ExportAiForm.vue";
import ExportVcForm from "../../app/practitioner-portal/admin/ExportVcForm.vue";
import { shortDateFormat } from "../../helpers/platform";
import ExportExaminationsFormWithRegion from "../../app/practitioner-portal/generic/export/ExportExaminationsFormWithRegion.vue";

export default {
  components: {
    ExportView,
    HeaderAdmin,
    Panel,
  },
  data() {
    return {
      isLoading: false,
      isExporting: false,
      availableCenters: [],
      availableRegions: [],
      errors: {},
    };
  },
  computed: {
    availableExports() {
      return [
        {
          type: "users",
          name: this.trans(`admin.exports.types.users.label`),
          form: ExportWithoutOptionsForm,
        },
        {
          type: "mole_consultations",
          name: this.trans(`admin.exports.types.mole_consultations.label`),
          form: ExportExaminationsForm,
          props: { dataAvailableCenters: this.availableCenters },
        },
        {
          type: "mole_statistics",
          name: this.trans(`admin.exports.types.mole_statistics.label`),
          form: ExportExaminationsFormWithRegion,
          props: {
            dataAvailableCenters: this.availableCenters,
            dataAvailableRegions: this.availableRegions,
          },
        },
        {
          type: "wound_consultations",
          name: this.trans(`admin.exports.types.wound_consultations.label`),
          form: ExportExaminationsFormWithRegion,
          props: {
            dataAvailableCenters: this.availableCenters,
            dataAvailableRegions: this.availableRegions,
          },
        },
        {
          type: "wound_statistics",
          name: this.trans(`admin.exports.types.wound_statistics.label`),
          form: ExportExaminationsFormWithRegion,
          props: {
            dataAvailableCenters: this.availableCenters,
            dataAvailableRegions: this.availableRegions,
          },
        },
        {
          type: "consult_answers",
          name: this.trans(`admin.exports.types.consult_answers.label`),
          form: ExportConsultAnswersForm,
          props: { dataAvailableCenters: this.availableCenters },
        },
        {
          type: "vc",
          name: this.trans(`admin.exports.types.vc.label`),
          form: ExportVcForm,
          props: { dataAvailableCenters: this.availableCenters },
        },
        {
          type: "ai",
          name: this.trans(`admin.exports.types.ai.label`),
          form: ExportAiForm,
          props: { dataAvailableCenters: this.availableCenters },
        },
        {
          type: "users_per_month",
          name: this.trans(`admin.exports.types.users_per_month.label`),
          form: ExportWithoutOptionsForm,
        },
        {
          type: "centers_per_month",
          name: this.trans(`admin.exports.types.centers_per_month.label`),
          form: ExportWithoutOptionsForm,
        },
        {
          type: "patient_log",
          name: this.trans(`admin.exports.types.patient_log.label`),
          form: ExportPatientLogForm,
        },
        {
          type: "user_log",
          name: this.trans(`admin.exports.types.user_log.label`),
          form: ExportUserLogForm,
        },
        {
          type: "mobile_os_versions",
          name: this.trans(`admin.exports.types.mobile_os_versions.label`),
          form: ExportWithoutOptionsForm,
        },
        {
          type: "wound_cases_per_center",
          name: this.trans(`admin.exports.types.wound_cases_per_center.label`),
          form: ExportWithoutOptionsForm,
        },
        {
          type: "analytics_current_year_examinations",
          name: this.trans(
            `admin.exports.types.analytics_current_year_examinations.label`,
          ),
          form: ExportWithoutOptionsForm,
        },
        {
          type: "analytics_current_year_patients",
          name: this.trans(
            `admin.exports.types.analytics_current_year_patients.label`,
          ),
          form: ExportWithoutOptionsForm,
        },
        {
          type: "analytics_last_year_examinations",
          name: this.trans(
            `admin.exports.types.analytics_last_year_examinations.label`,
          ),
          form: ExportWithoutOptionsForm,
        },
        {
          type: "analytics_last_year_patients",
          name: this.trans(
            `admin.exports.types.analytics_last_year_patients.label`,
          ),
          form: ExportWithoutOptionsForm,
        },
        {
          type: "analytics_year_before_last_examinations",
          name: this.trans(
            `admin.exports.types.analytics_year_before_last_examinations.label`,
          ),
          form: ExportWithoutOptionsForm,
        },
        {
          type: "analytics_year_before_last_patients",
          name: this.trans(
            `admin.exports.types.analytics_year_before_last_patients.label`,
          ),
          form: ExportWithoutOptionsForm,
        },
      ];
    },
  },
  mounted: function () {
    this.loadData();
  },
  methods: {
    loadData: function () {
      this.isLoading = true;
      axios
        .get("/api/internal/admin/export")
        .then((data) => {
          this.availableCenters = _filter(_has("id"), data.data.centers);
          this.availableRegions = _filter(_has("id"), data.data.regions);
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.userErrorMessage(error);
        });
    },
    /**
     * @param {FormData} formData
     */
    exportCsv: function (formData) {
      this.isExporting = true;
      this.errors = {};

      formData.set("responseType", "arraybuffer");

      // TODO: the data should be returned in ISO date format, so this conversion is not needed
      const format = shortDateFormat();
      if (formData.get("fromDate")) {
        formData.set(
          "dateFrom",
          moment(formData.get("fromDate"), format).format("YYYY-MM-DD"),
        );
      }
      if (formData.get("untilDate")) {
        formData.set(
          "dateUntil",
          moment(formData.get("untilDate"), format).format("YYYY-MM-DD"),
        );
      }

      axios
        .post("/api/internal/admin/export", formData)
        .then((response) => {
          const blob = new Blob(
            [new Uint8Array([0xef, 0xbb, 0xbf]), response.data],
            {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            },
          );

          const fileName = contentDisposition.parse(
            response.headers["content-disposition"],
          ).parameters.filename;

          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileName);
          }

          const data = window.URL.createObjectURL(blob);
          var link = document.createElement("a");
          link.href = data;
          link.download = fileName;
          link.click();

          setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data), 100;
          });

          this.isExporting = false;
        })
        .catch((error) => {
          this.errors = error.response.data;
          this.isExporting = false;
          this.userErrorMessage(error);
        });
    },
  },
};
</script>
