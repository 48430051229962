<template>
  <nav
    class="examination-header tw-pb-2 md:tw-pb-0 md:tw-flex tw-justify-between tw-items-center tw-border-0 tw-border-b-2 tw-border-[#ccc] tw-border-solid"
  >
    <div class="tw-flex tw-justify-end md:tw-order-1">
      <ExaminationNavigator
        v-if="examinationNavigation"
        :data-examination-id="examinationId"
        :data-examination-navigation="examinationNavigation"
      />
    </div>
    <ul class="nav nav-tabs tabs-bordered">
      <li class="nav-item" @click="onBackClick">
        <router-link :to="{ name: 'Evaluation' }" class="nav-link">
          <span class="visible-xs">
            <i class="fal fa-arrow-left" />
          </span>
          <span class="hidden-xs">{{ trans("general.back") }}</span>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          :to="{ name: routeName + 'Home' }"
          class="nav-link"
          exact-active-class="active"
        >
          <span>{{ trans("examination.tabLabelConsultation") }}</span>
        </router-link>
      </li>
      <li
        v-if="
          productHasPermission(
            PRODUCT_PERMISSIONS.COMPARE_IMAGES,
            productDefinition,
          )
        "
        class="nav-item"
      >
        <router-link
          :to="{ name: routeName + 'Images' }"
          class="nav-link"
          exact-active-class="active"
        >
          <span>{{ trans("examination.tabLabelImageCompare") }}</span>
          <span v-if="totalExaminations" class="consultationCount"
            >({{ totalExaminations }})</span
          >
        </router-link>
      </li>
      <li class="nav-item">
        <a
          v-if="
            productHasPermission(
              PRODUCT_PERMISSIONS.PRINT_CASE,
              productDefinition,
            )
          "
          class="nav-link"
          @click="initializePrint"
        >
          <span
            >{{ trans("general.actionPrintText") }} /
            <i class="fas fa-print" />
          </span>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
import { getHistoryState } from "../../../../../lib/history-state";
import { PRODUCT_PERMISSIONS } from "../../../../../helpers/definitions";
import { productHasPermission } from "../../../../../helpers/product";
import Button from "../../../../Layout/Button.vue";
import ExaminationNavigator from "./ExaminationNavigator.vue";

export default {
  components: { Button, ExaminationNavigator },
  props: {
    examinationId: String,
    consensusNotifications: [String, Number],
    totalExaminations: Number,
    productDefinition: Object,
  },
  data: function () {
    return {
      PRODUCT_PERMISSIONS: PRODUCT_PERMISSIONS,
      productHasPermission: productHasPermission,
    };
  },
  computed: {
    examinationNavigation() {
      return getHistoryState("examinationNavigationDetails");
    },
    routeName() {
      return this.productDefinition.meta.name;
    },
  },
  methods: {
    mountImageCompare: function () {
      this.$emit("mountImageCompare");
    },
    initializePrint: function () {
      this.$emit("initializePrint");
    },
    onBackClick: function () {
      this.cancelRequests();
    },
    cancelRequests: function () {
      this.$store.state.cancelTokens.forEach((token) => {
        token.cancel();
      });
      this.$store.commit("resetCancelTokens");
    },
  },
};
</script>

<style lang="scss" scoped>
.examination-header {
  .tabs-bordered {
    border-bottom-width: 0 !important;
  }
}
</style>
