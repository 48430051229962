<template>
  <div class="tab-content">
    <div class="tab-pane active">
      <Panel :title="trans('center.exportToExcel')">
        <clip-loader v-if="loading" />
        <div v-if="!loading">
          <time-pickers-menu
            :loading="loading"
            :hide-heading="true"
            @update-date="updateDate"
          />
          <button class="btn btn-primary m-t-10" @click="exportCsv">
            {{ trans("center.exportToExcel") }}
          </button>
        </div>
      </Panel>
    </div>
  </div>
</template>

<script>
import Panel from "../../Layout/Panel.vue";
import TimePickersMenu from "../../Statistics/StatisticsComponents/StatisticsUtils/TimePicker.vue";

export default {
  components: { TimePickersMenu, Panel },
  data() {
    return {
      toDate: "",
      fromDate: "",
      loading: false,
      errors: [],
    };
  },
  computed: {
    researchId() {
      return this.$route.params.studyId;
    },
  },
  methods: {
    updateDate(fromDate, toDate) {
      this.fromDate = fromDate;
      this.toDate = toDate;
    },
    exportCsv: function () {
      this.loading = true;
      this.errors = [];
      axios
        .get(
          `/api/internal/study-admin/results/export/${this.researchId}/${this.fromDate}/${this.toDate}`,
        )
        .then((response) => {
          const blob = new Blob(
            [new Uint8Array([0xef, 0xbb, 0xbf]), response.data],
            {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            },
          );

          // Filename
          const centerName = this.$store.state.user.primaryCenter.name.replace(
            /\s+/g,
            "_",
          );
          const date = new Date().toISOString().split("T")[0];
          const fileName = `${centerName}_research_results_${date}.csv`;

          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileName);
          }

          const data = window.URL.createObjectURL(blob);
          let link = document.createElement("a");
          link.href = data;
          link.download = fileName;
          link.click();

          setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data), 100;
          });

          this.loading = false;
        })
        .catch((error) => {
          this.errors = error.response.data;
          this.loading = false;
          this.userErrorMessage(error);
        });
    },
  },
};
</script>
