import { appConfigGet } from "./app-config";
import { lang } from "../../../i18n";

/**
 * AppSupportFooter Get store resource factory provider.
 */
export function appSupportFooterGetProvider() {
  return function appSupportFooterGet(): Promise<{ data: string }> {
    return appConfigGet().then((appConfig) => {
      const platformFooterHtml = lang.get(
        `support.${appConfig.data.platform.name}.footerHtml`,
        {
          appVersion: appConfig.data.version,
        },
      );

      const data = platformFooterHtml
        ? platformFooterHtml
        : lang.get("support.general.footerHtml", {
            supportEmail: appConfig.data.company.support.email,
            supportPhone: appConfig.data.company.support.phone,
            appVersion: appConfig.data.version,
          });
      return { data };
    });
  };
}
/**
 * AppSupportFooter Get store resource factory.
 */
export const appSupportFooterGet = appSupportFooterGetProvider({
  lang,
  appConfigGet,
});
