<template>
  <div class="time-pickers-container tw-space-y-2">
    <label v-if="!hideHeading">{{ trans("statistics.timeInterval") }}</label>
    <v-select
      v-model="selectedTimePeriod"
      name="timeSelection"
      :options="timePeriods"
      :reduce="(timePeriod) => timePeriod.id"
      label="text"
      @update:model-value="updateTimePeriod($event)"
    />

    <div v-show="customTimePeriod || !hideCustom">
      <div class="lg:tw-flex max-lg:tw-space-y-2 tw-gap-2 tw-items-center">
        <div class="tw-flex-1">
          <div>{{ trans("evaluation.tableColumnCreatedAtFrom") }}</div>
          <custom-datepicker
            name="fromDate"
            :date="dateFrom"
            :placeholder="trans('evaluation.tableColumnCreatedAtFrom')"
            @on-date-change="setDate('dateFrom', $event)"
          />
        </div>

        <div class="tw-flex-1">
          <div>{{ trans("evaluation.tableColumnCreatedAtTo") }}</div>
          <custom-datepicker
            name="untilDate"
            :date="dateTo"
            :placeholder="trans('evaluation.tableColumnCreatedAtTo')"
            @on-date-change="setDate('dateTo', $event)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    loading: Boolean,
    hideHeading: Boolean,
    hideCustom: Boolean,
  },
  data() {
    return {
      customTimePeriod: false,
      timePeriods: [
        { id: "currentWeek", text: this.trans("statistics.currentWeek") },
        { id: "lastWeek", text: this.trans("statistics.lastWeek") },
        { id: "currentMonth", text: this.trans("statistics.currentMonth") },
        { id: "lastMonth", text: this.trans("statistics.lastMonth") },
        { id: "currentYear", text: this.trans("statistics.currentYear") },
        { id: "lastYear", text: this.trans("statistics.lastYear") },
        { id: "all", text: this.trans("statistics.all") },
        { id: "custom", text: this.trans("statistics.custom") },
      ],
      selectedTimePeriod: {
        id: "currentYear",
        text: this.trans("statistics.currentYear"),
      },
      dateFrom: moment().subtract(12, "M").format("YYYY-MM-DD"),
      dateTo: moment().format("YYYY-MM-DD"),
    };
  },
  created: function () {
    this.updateTimePeriod();
  },
  methods: {
    updateTimePeriod: function (selectedTimePeriod) {
      this.customTimePeriod = false;

      switch (selectedTimePeriod) {
        case "all":
          this.dateTo = moment().format("YYYY-MM-DD");
          this.dateFrom = "2014-01-01";
          break;
        case "currentWeek":
          this.dateTo = moment().format("YYYY-MM-DD");
          this.dateFrom = moment().startOf("isoWeek").format("YYYY-MM-DD");
          break;
        case "lastWeek":
          this.dateTo = moment()
            .subtract(1, "weeks")
            .endOf("isoWeek")
            .format("YYYY-MM-DD");
          this.dateFrom = moment()
            .subtract(1, "weeks")
            .startOf("isoWeek")
            .format("YYYY-MM-DD");
          break;
        case "currentMonth":
          this.dateTo = moment().format("YYYY-MM-DD");
          this.dateFrom = moment().startOf("months").format("YYYY-MM-DD");
          break;
        case "lastMonth":
          this.dateTo = moment()
            .subtract(1, "months")
            .endOf("months")
            .format("YYYY-MM-DD");
          this.dateFrom = moment()
            .subtract(1, "months")
            .startOf("months")
            .format("YYYY-MM-DD");
          break;
        case "currentYear":
          this.dateTo = moment().format("YYYY-MM-DD");
          this.dateFrom = moment().startOf("years").format("YYYY-MM-DD");
          break;
        case "lastYear":
          this.dateTo = moment()
            .subtract(1, "years")
            .endOf("years")
            .format("YYYY-MM-DD");
          this.dateFrom = moment()
            .subtract(1, "years")
            .startOf("years")
            .format("YYYY-MM-DD");
          break;
        case "custom":
          this.customTimePeriod = true;
          break;
        default:
          this.selectedTimePeriod = "currentYear";
          this.dateTo = moment().format("YYYY-MM-DD");
          this.dateFrom = moment().startOf("years").format("YYYY-MM-DD");
      }

      this.$emit("updateDate", this.dateFrom, this.dateTo);
    },
    setDate: function (target, date) {
      if (this[target] !== date) {
        this.selectedTimePeriod = "custom";
        this.updateTimePeriod("custom");
        this[target] = date;
      }

      this.$emit("updateDate", this.dateFrom, this.dateTo);
    },
    clearDate: function () {
      this.customTimePeriod = false;
      this.selectedTimePeriod = "year";
      this.updateTimePeriod("year");
    },
  },
};
</script>
