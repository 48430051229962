<template>
  <div class="panel panel-default panel-fill">
    <div class="panel-heading panel-heading-underline">
      <h3 class="panel-title">
        {{ trans("examination.imagesHeadline") }}
        <info-popup
          v-if="!product.toLowerCase().includes('photo')"
          :text-content="getInfoPopupText()"
        />
      </h3>
    </div>
    <div class="panel-body">
      <image-gallery
        :images="images"
        :data-image-metadata="dataImageMetadata"
        :product-type="productType"
        :case-id="caseId"
        :use-column="false"
        :is-loading="false"
      >
      </image-gallery>
    </div>
  </div>
</template>

<script>
import ImageGallery from "./ImageGallery.vue";
import InfoPopup from "../../Utils/InfoPopup.vue";

import { PRODUCTS } from "../../../helpers/definitions";
import { infoPopupText } from "../../../helpers/misc";

export default {
  components: {
    ImageGallery,
    InfoPopup,
  },
  props: {
    images: {},
    dataImageMetadata: Object,
    product: String,
    caseId: Number,
  },
  data: function () {
    return {
      PRODUCTS: PRODUCTS,
    };
  },
  methods: {
    getInfoPopupText: function () {
      return Object.values(this.images)
        .map((image) => `${this.trans("infoText." + image.type)}`)
        .join("<br />");
    },
  },
};
</script>

<style lang="scss" scoped>
.image-container {
  min-height: 200px;
}
</style>
