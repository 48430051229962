<template>
  <UseStoreResource :data-resource="resource">
    <div
      class="form-image-upload tw-w-[200px]"
      :class="{ 'form-image-upload--preview': avatar }"
    >
      <input
        id="fileInput"
        :key="avatar"
        name="fileInput"
        type="file"
        @change="handleFileInput"
      />
      <label
        ref="fileUploadRef"
        for="fileInput"
        class="mb-0 uploadLabel"
        :style="{
          pointerEvents: avatar ? 'none' : undefined,
        }"
      >
        <div v-if="avatar">
          <img :src="avatar" />
        </div>
        <span v-if="!avatar" class="upload-caption">
          <div class="upload-caption__icon">
            <i
              id="pick-avatar"
              class="fas fa-cloud-upload-alt uploadOverlayIcon"
            />
          </div>
          {{ lang.get("center.uploadLogo") }}
        </span>
      </label>
      <div v-if="avatar" class="dropdown">
        <button
          class="form-image-upload__edit btn btn-grey"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          type="button"
        >
          <i class="fas fa-pen editIcon" />
          <span>{{ lang.get("center.edit") }}</span>
        </button>
        <div class="dropdown-menu">
          <button class="dropdown-item" type="button" @click="onEditClick">
            {{ lang.get("center.uploadLogo") }}
          </button>
          <button
            class="dropdown-item"
            type="button"
            @click="$emit('avatarDelete', { identifier })"
          >
            {{ lang.get("center.removeLogo") }}
          </button>
        </div>
      </div>
    </div>
  </UseStoreResource>
</template>

<script setup lang="ts">
import { computed, ref, toRefs } from "vue";
import { lang } from "../i18n";
import { AvatarResource } from "../store/core/avatar";
import UseStoreResource from "../design-system/UseStoreResource.vue";

const fileUploadRef = ref();
const props = defineProps<{
  dataResource: AvatarResource;
}>();
const { dataResource } = toRefs(props);

const resource = dataResource;
const identifier = computed(() => resource.value.data.value?.identifier);
const avatar = computed(() => resource.value.data.value?.avatar);

function onEditClick() {
  fileUploadRef.value.click();
}

/**
 * Temporary solution just to keep this upload working.
 * We should update this to include resizing and compressing the image.
 * Previous implementation had a max-width of 512px.
 */
function handleFileInput(event: InputEvent) {
  const logo = (event.target as HTMLInputElement).files?.item(0);
  if (logo) {
    emit("avatarUpload", {
      identifier: identifier.value,
      data: logo,
    });
  }
}

const emit = defineEmits<{
  (event: "avatarUpload", payload: { identifier: string; data: unknown }): void;
  (event: "avatarDelete", payload: { identifier: string }): void;
}>();
</script>
<style lang="scss">
.form-image-upload {
  #fileInput {
    display: none !important;
  }
}
</style>
<style lang="scss" scoped>
.uploadLabel {
  cursor: pointer;
}

.form-image-upload {
  background: #fff;

  &:not(.form-image-upload--preview) {
    text-align: center;
    border: 1px solid gainsboro;

    label {
      padding: 1em;
    }
  }

  img,
  label {
    width: 100%;
  }
}

.upload-caption__icon {
  font-size: 3em;
}

.form-image-upload__edit {
  margin-top: 0.2em;
}

.editIcon {
  font-size: 0.9em;
}
</style>
