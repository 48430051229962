<template>
  <login-form-container v-if="errorMessage">
    <div class="text-center mt-4 mb-5">
      <div class="my-5 text-danger">
        <h6 class="mb-3">
          {{ trans("errorMessages.loginErrorHeader") }}
        </h6>
        <p>
          {{ errorMessage }}
        </p>
      </div>
      <router-link to="/" class="btn btn-primary">
        {{ trans("errorMessages.loginErrorButtonText") }}
      </router-link>
    </div>
  </login-form-container>
</template>

<script>
import LoginFormContainer from "./LoginFormContainer.vue";
export default {
  components: {
    LoginFormContainer,
  },
  data: function () {
    return {
      errorMessage: this.$route.query.error,
    };
  },
  mounted: function () {
    this.handleSvenkEIdentitetError();
  },
  methods: {
    handleSvenkEIdentitetError: function () {
      if (this.$route.query.message) {
        this.errorMessage = this.trans(this.$route.query.message);
        if (this.$route.query.method) {
          this.errorMessage = this.errorMessage.replace(
            ":method",
            this.$route.query.method,
          );
        }
      } else {
        this.$router.push({ name: "Auth" });
      }
    },
  },
};
</script>
