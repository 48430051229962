<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <div class="wrapper-page">
            <div class="container-box m-t-40">
              <div class="account-content">
                <form
                  v-if="!isVerifyToken"
                  class="form-horizontal"
                  @submit.prevent="login()"
                >
                  <div
                    class="form-group m-b-20"
                    :class="{ 'has-error': username.hasErrors }"
                  >
                    <div class="col-xs-12">
                      <label for="username">{{
                        trans("users.usernameLabel")
                      }}</label>
                      <input
                        id="username"
                        v-model="username.value"
                        v-focus
                        type="text"
                        class="form-control"
                        name="username"
                        required
                        :placeholder="trans('auth.usernameOrEmailPlaceholder')"
                      />

                      <span v-if="username.hasErrors" class="help-block">
                        <strong>{{ username.errorMessage }}</strong>
                      </span>
                    </div>
                  </div>
                  <div
                    class="form-group m-b-20"
                    :class="{ 'has-error': password.hasErrors }"
                  >
                    <div class="col-xs-12">
                      <label for="password">{{
                        trans("auth.passwordLabel")
                      }}</label>
                      <input
                        id="password"
                        v-model="password.value"
                        type="password"
                        class="form-control"
                        name="password"
                        required
                        autocomplete="password"
                      />

                      <span v-if="password.hasErrors" class="help-block">
                        <strong>{{ password.errorMessage }}</strong>
                      </span>
                    </div>
                  </div>

                  <div class="resend-btn">
                    <router-link to="/auth/password/reset">
                      {{ trans("auth.forgotPasswordLinkText") }}
                    </router-link>
                  </div>

                  <div class="form-group account-btn text-center m-t-10">
                    <div class="col-xs-12">
                      <clip-loader v-show="loading" />
                      <button
                        v-if="!loading"
                        class="btn btn-custom btn-block btn-lg"
                        type="submit"
                      >
                        {{ trans("auth.signUnLoginButton") }}
                      </button>
                    </div>
                  </div>
                </form>

                <form
                  v-if="isVerifyToken"
                  class="form-horizontal"
                  @submit.prevent="verify()"
                >
                  <div
                    class="form-group m-b-20"
                    :class="{ 'has-error': key.hasErrors }"
                  >
                    <div class="col-xs-12">
                      <label
                        v-if="method === 'username-password:sms'"
                        for="token"
                        >{{ trans("auth.keyLabel") }}</label
                      >
                      <label
                        v-if="method === 'username-password:auth-app'"
                        for="token"
                        >{{ trans("auth.authAppEnterCodePlaceholder") }}</label
                      >

                      <div class="code-inputs">
                        <input
                          v-for="(digit, index) in 6"
                          :key="index"
                          :ref="'box' + index"
                          v-model="code[index]"
                          type="text"
                          inputmode="numeric"
                          maxlength="1"
                          class="form-control code-box"
                          autocomplete="off"
                          required="required"
                          @keydown="focusNext(index, $event)"
                          @paste="focusNext(index, $event)"
                          @input="enforceNumericInput(index, $event)"
                        />
                      </div>

                      <span v-if="key.hasErrors" class="help-block">
                        <strong>{{ key.errorMessage }}</strong>
                      </span>
                    </div>
                  </div>

                  <div
                    v-if="method === 'username-password:sms'"
                    class="resend-btn"
                  >
                    <a v-if="resendAvailable" href="#" @click="resend()">{{
                      trans("auth.resendCode")
                    }}</a>
                    <span v-if="!resendAvailable">
                      {{ trans("auth.resendAvailableIn") }}
                      {{ resendAvailabilityTimer }}
                      {{ trans("auth.seconds") }}
                    </span>
                  </div>

                  <div class="form-group account-btn text-center m-t-10">
                    <div v-show="loading" class="col-xs-12">
                      <clip-loader />
                    </div>
                    <div v-if="!loading" class="col-xs-12">
                      <button
                        class="btn btn-custom btn-block btn-lg"
                        type="submit"
                      >
                        {{ trans("auth.tokenButtonConfirm") }}
                      </button>
                      <a class="btn btn-grey btn-block btn-lg" @click="back">{{
                        trans("general.back")
                      }}</a>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <!-- end card-box-->
          </div>
          <!-- end wrapper -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { navigateToHomepage } from "./helpers/authHelpers";
import { practitionerAuthToken } from "../../app/practitioner-portal/auth/practitioner-auth-token";

export default {
  props: {
    // username-password:sms or username-password:auth-app
    method: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      resendAvailable: true,
      resendAvailableAfter: 20,
      resendAvailabilityTimer: 20,
      username: {
        value: "",
        hasErrors: false,
        errorMessage: "",
      },
      password: {
        value: "",
        hasErrors: false,
        errorMessage: "",
      },
      key: {
        value: "",
        hasErrors: false,
        errorMessage: "",
      },
      isVerifyToken: false,
      token: "",
      dermicusPlatform: "",
      code: Array(6).fill(""),
    };
  },
  watch: {
    method() {
      this.back();
    },
    isVerifyToken(newValue) {
      if (newValue) {
        this.$nextTick(() => {
          this.$refs.box0[0].focus();
        });
      }
    },
    code: {
      handler(newCode) {
        if (newCode.every((digit) => digit !== "")) {
          this.verify(); // Auto-submit when all input boxes are filled
        }
      },
      deep: true,
    },
  },
  mounted: function () {
    this.dermicusPlatform = window.dermicusPlatform;
  },
  methods: {
    login: function () {
      let instance = this;
      instance.loading = true;
      let formData = {
        username: instance.username.value,
        password: instance.password.value,
        method: this.method,
      };
      axios
        .post("/api/internal/auth/sms", formData)
        .then((response) => {
          instance.loading = false;
          if (response.data.errors) {
            if (response.data.errors.username) {
              this.username.hasErrors = true;
              this.username.errorMessage = response.data.errors.username;
              this.username.value = response.data.inputs.username;
            }

            if (response.data.errors.pasword) {
              this.password.hasErrors = true;
              this.password.errorMessage = response.data.errors.pasword;
              this.password.value = response.data.inputs.pasword;
            }
          }

          instance.token = response.data.token;
          if (response.data.token) {
            this.isVerifyToken = true;
            instance.token = response.data.token;
          }
        })
        .catch(function (error) {
          if (error.response) {
            let status = error.response.status;
            let data = error.response.data;

            if (status == 422) {
              if (data.username) {
                instance.username.errorMessage = data.username[0];
                instance.username.hasErrors = true;
              }

              if (data.password) {
                instance.password.errorMessage = data.password[0];
                instance.password.hasErrors = true;
                instance.password.value = "";
              }
            }
          }

          instance.loading = false;
          instance.userErrorMessage(error);
        });
    },
    makeResendAvailable: function () {
      this.resendAvailabilityTimer = this.resendAvailableAfter;
      this.resendAvailable = false;
      var n = this.resendAvailableAfter;
      var tm = setInterval(countDown, 1000);
      var instance = this;

      function countDown() {
        n--;
        if (n == 0) {
          clearInterval(tm);
        }
        instance.resendAvailabilityTimer = n;
      }

      setTimeout(() => {
        this.resendAvailable = true;
      }, this.resendAvailableAfter * 1000);
    },
    focusNext(index, event) {
      if (event.type === "paste") {
        const paste = (event.clipboardData || window.clipboardData).getData(
          "text",
        );
        const pasteArray = paste.split("").slice(0, 6); // Limit to 6 characters
        pasteArray.forEach((char, i) => {
          if (index + i < 6) {
            this.code[index + i] = char;
            this.$refs["box" + (index + i)][0].focus();
          }
        });
      } else if (event.key === "Backspace") {
        if (this.code[index] === "" && index > 0) {
          this.$refs["box" + (index - 1)][0].focus();
          this.code[index - 1] = "";
        }
      } else {
        if (this.code[index].length === 1 && index < 5) {
          this.$refs["box" + (index + 1)][0].focus();
          this.$refs["box" + (index + 1)][0].select();
        }
      }
    },
    resend: function () {
      let formData = { token: this.token, key: this.key.value };
      axios
        .post("/api/internal/auth/sms/resend", formData)
        .then((response) => {
          this.$notify({
            text: this.trans("auth.codeResent"),
            type: "success",
            duration: 1000,
          });
          this.makeResendAvailable();
        })
        .catch((error) => {
          this.userErrorMessage(error);
        });
    },
    verify: function () {
      let instance = this;
      instance.loading = true;

      let formData = { token: instance.token, key: this.code.join("") };
      axios
        .post("/api/internal/auth/" + this.method + "/verify", formData)
        .then((response) => {
          instance.loading = false;
          practitionerAuthToken.set(response.data.accessToken);
          localStorage.removeItem("last_query_uri");
          navigateToHomepage(response);
        })
        .catch(function (error) {
          if (error.response) {
            let status = error.response.status;
            let data = error.response.data;

            if (status == 422 || status == 429) {
              if (data.key) {
                instance.key.errorMessage = data.key;
                instance.key.hasErrors = true;
              }
            }
          }

          instance.loading = false;
          instance.userErrorMessage(error);
        });
    },
    back: function () {
      this.isVerifyToken = false;
      this.token = "";
      this.key = {};

      this.username.hasErrors = false;
      this.username.errorMessage = "";
      this.password.hasErrors = false;
      this.password.errorMessage = "";
      this.key.hasErrors = false;
      this.key.errorMessage = "";
    },
    enforceNumericInput(index, event) {
      const value = event.target.value;

      if (!/^\d$/.test(value)) {
        this.code[index] = "";
      }

      if (value.length > 1) {
        this.code[index] = value.slice(0, 1);
      }
    },
  },
};
</script>

<style>
.code-inputs {
  display: flex;
  gap: 8px;
}
</style>
