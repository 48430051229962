import { practitionerHttpService } from "../../app/practitioner-portal/store/practitioner-http-service";
import { AvatarResponse } from "../../../../../app/Http/Controllers/Api/Internal/Helpers/HasAvatarFileControl";
import { UseQueryOptions, UseQueryReturnType } from "@tanstack/vue-query";
import { queryClient } from "../api/index";

export type AvatarData = AvatarResponse & { identifier: string };
export type AvatarResource = UseQueryReturnType<AvatarData, unknown>;

const avatarKey = (identifier: string) => ["avatar", identifier];

export function avatarGet(identifier: string): UseQueryOptions<AvatarData> {
  return {
    queryKey: avatarKey(identifier),
    queryFn: () =>
      practitionerHttpService
        .get<AvatarResponse>(identifier)
        .then(({ data }) => ({ ...data, identifier })),
  };
}

export function avatarUpload(identifier: string, data: File) {
  const formData = new FormData();
  formData.append("file", data);

  return practitionerHttpService
    .post(identifier, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(({ data }) => {
      const result = { ...data, identifier };
      queryClient.setQueryData(avatarKey(identifier), result);
      return result;
    });
}

export function avatarDelete(identifier: string) {
  return practitionerHttpService.delete(identifier).then(({ data }) => {
    const result = { ...data, identifier };
    queryClient.setQueryData(avatarKey(identifier), result);
    return result;
  });
}
