import { useQuery, useMutation } from "@tanstack/vue-query";
import { lang } from "../../i18n";
import { computed } from "vue";
import { userErrorMessage } from "../../mixins/UserErrorMessage.vue";
import { avatarUpload, avatarDelete } from "../core/avatar";
import { centerAvatarGet } from "../core/center-settings";
import { regionAvatarGet } from "../core/region-settings";
import { notify } from "@kyvg/vue3-notification";

export function useCenterAvatar(centerId: number) {
  const centerAvatarResource = useQuery(
    computed(() => centerAvatarGet(centerId)),
  );

  return centerAvatarResource;
}

export function useCenterAvatarUpload() {
  const onAvatarUpload = useMutation({
    mutationFn: ({ identifier, data }: { identifier: string; data: File }) =>
      avatarUpload(identifier, data),
    onSuccess() {
      notify({
        text: lang.get("general.notificationSaved"),
        type: "success",
        duration: 1000,
      });
    },
    onError: userErrorMessage,
  });

  return onAvatarUpload;
}

export function useCenterAvatarDelete() {
  const onAvatarDelete = useMutation({
    mutationFn: ({ identifier }: { identifier: string }) =>
      avatarDelete(identifier),
    onSuccess() {
      notify({
        text: lang.get("general.notificationSaved"),
        type: "success",
        duration: 1000,
      });
    },
    onError: userErrorMessage,
  });

  return onAvatarDelete;
}

export function useRegionAvatar(regionId: number) {
  const regionAvatarResource = useQuery(
    computed(() => regionAvatarGet(regionId)),
  );

  return regionAvatarResource;
}

export function useRegionAvatarUpload() {
  const onAvatarUpload = useMutation({
    mutationFn: ({ identifier, data }: { identifier: string; data: File }) =>
      avatarUpload(identifier, data),
    onSuccess() {
      notify({
        text: lang.get("general.notificationSaved"),
        type: "success",
        duration: 1000,
      });
    },
    onError: userErrorMessage,
  });

  return onAvatarUpload;
}

export function useRegionAvatarDelete() {
  const onAvatarDelete = useMutation({
    mutationFn: ({ identifier }: { identifier: string }) =>
      avatarDelete(identifier),
    onSuccess() {
      notify({
        text: lang.get("general.notificationSaved"),
        type: "success",
        duration: 1000,
      });
    },
    onError: userErrorMessage,
  });

  return onAvatarDelete;
}
