<template>
  <div>
    <div class="tw-pt-3">
      <patient-info-header :data-patient="patient" />
      <hr />
    </div>
    <image-compare
      ref="imageCompare"
      :examination-id="examination.id"
      :type="product.meta.id"
    />
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import PatientInfoHeader from "../../../../Patients/PatientInfoHeader.vue";
import ImageCompare from "../../../AddOns/ImageCompare.vue";

export default {
  components: {
    PatientInfoHeader,
    ImageCompare,
  },
  props: {
    patient: Object,
    examination: Object,
    product: Object,
  },
  setup() {
    const imageCompare = ref(null);

    onMounted(() => {
      if (imageCompare.value) {
        imageCompare.value.getImages();
      }
    });

    return {
      imageCompare,
    };
  },
};
</script>
