<template>
  <field-panel
    :title="title"
    :actions-menu="
      useActionMenu
        ? {
            onAction: onAction,
            examinationActions: examinationActions,
            product: product,
            loading: loading,
            errorUpdatingActions: errorUpdatingActions,
            caseClosedAt: caseClosedAt,
            viewOption: viewOption,
          }
        : undefined
    "
    :info-popup-text="getInfoPopupText()"
  >
    <div class="panel-body">
      <div v-if="examinationData.length > 0" class="row">
        <div
          v-for="(field, index) in examinationData"
          :key="index"
          class="col-md-6 mb-3 u-hide-if-empty"
        >
          <field
            :examination-id="examination.id"
            :title="field.title"
            :data="field.data"
            :type="field.type"
            :extra-options="field.extraOptions"
            :view-option="viewOption"
          />
        </div>

        <column v-if="canSeeCaseMarkings() && examinationActions.isPrioritized">
          <priority-icon location="general-info" />
        </column>

        <column v-if="canSeeCaseMarkings() && examinationActions.isFlagged">
          <flag-icon location="general-info" />
        </column>

        <column v-if="canSeeCaseMarkings() && examinationActions.isBookmarked">
          <bookmark-icon location="general-info" />
        </column>

        <column
          v-if="canMarkAsTestPatient() && examinationActions.isTestPatient"
        >
          <test-icon location="general-info" />
        </column>

        <column
          v-if="
            $store.state.user.has_ai_prioritization && examination.aiPriority
          "
        >
          <ai-priority-icon
            :priority="examination.aiPriority"
            location="general-info"
          />
        </column>
      </div>
    </div>
    <VueJsDialogMixinWrapper
      v-if="!!showPrintDialogWithOptions"
      :data-component="showPrintDialogComponent"
      :data-options="showPrintDialogWithOptions"
      @close="closePrintCase"
    />
  </field-panel>
</template>

<script>
import FieldPanel from "./components/FieldPanel.vue";
import Column from "./components/Column.vue";
import AiPriorityIcon from "../../../Utils/AiFeatures/AiPriorityIcon.vue";
import PriorityIcon from "../../../Utils/ActionIcons/PriorityIcon.vue";
import FlagIcon from "../../../Utils/ActionIcons/FlagIcon.vue";
import BookmarkIcon from "../../../Utils/ActionIcons/BookmarkIcon.vue";
import TestIcon from "../../../Utils/ActionIcons/TestIcon.vue";
import CustomPrintViewDialog from "../../Examination/ExaminationComponents/components/PrintCaseView.vue";
import Notifications from "../../.././Utils/notifications";

import { FIELD_CONSTANTS } from "../fieldMap";
import { getFieldType } from "../../../../helpers/product";
import {
  extractNestedKeys,
  transformExaminationToSnakeCase,
  infoPopupText,
  camelToSnakeCase,
} from "../../../../helpers/misc";
import {
  PRODUCT_ACTION_NAMES,
  STORE_CONSTANTS,
} from "../../../../helpers/definitions";
import { examinationCloseConfirm } from "../../examination-close-confirm";
import VueJsDialogMixinWrapper from "../../../../mixins/VueJsDialogMixinWrapper.vue";
import { practitionerHttpService } from "../../../../app/practitioner-portal/store/practitioner-http-service";

export default {
  components: {
    VueJsDialogMixinWrapper,
    FieldPanel,
    Column,
    AiPriorityIcon,
    PriorityIcon,
    FlagIcon,
    BookmarkIcon,
    TestIcon,
  },
  props: {
    fields: Array,
    examination: Object,
    product: Object,
    options: Object || undefined,
    viewOption: String,
  },
  data() {
    return {
      examinationData: [],
      FIELD_CONSTANTS: FIELD_CONSTANTS,
      title: "examination.generalHeadline",
      examinationActions: {
        isPrioritized: false,
        isFlagged: false,
        isBookmarked: false,
        isTestPatient: false,
      },
      loading: false,
      useActionMenu: false,
      errorUpdatingActions: false,
      caseClosedAt: null,
      showPrintDialogComponent: CustomPrintViewDialog,
      showPrintDialogWithOptions: null,
    };
  },
  watch: {
    "$store.state.currentExamination.examinationData": function () {
      const updatedExamination =
        this.$store.state.currentExamination.examinationData;

      this.mapData(updatedExamination);
    },
  },
  mounted: function () {
    this.mapData();
  },
  methods: {
    mapData: function (updatedExamination) {
      this.setUseActionMenu();
      this.setExaminationActions();
      const { fields, options } = this;
      const examination = updatedExamination
        ? transformExaminationToSnakeCase(updatedExamination)
        : transformExaminationToSnakeCase(this.examination);

      // flag case being closed
      this.caseClosedAt = examination.case_close_at;

      const examinationData = [];
      fields.forEach((field) => {
        const excludeField =
          options &&
          "excluded_fields" in options &&
          options.excluded_fields.includes(field.name);
        // WARNING: Decide on way to handle nested values. Either allow nested fields in product definition, or not use nested values or something like below:
        const nestedKeys = extractNestedKeys(field.name);
        const keyIsNested = nestedKeys.length > 1;
        const name = keyIsNested ? nestedKeys[0] : field.name;
        const title = keyIsNested ? nestedKeys[1] : field.name;

        if (
          !excludeField &&
          ((field.visible && name in examination) ||
            (keyIsNested &&
              name in examination &&
              nestedKeys[1] in examination[name]))
        ) {
          examinationData.push({
            title: title,
            data: keyIsNested
              ? examination[nestedKeys[0]][nestedKeys[1]]
              : examination[name],
            disabled: field.disabled,
            type: getFieldType(field),
            extraOptions: {
              examination: examination,
              closeCasesGeneralInfo: this.canCloseCaseGeneralInfo(field),
              tooltip: this.getTooltipData(field, examination),
            },
          });
        }
      });
      this.examinationData = examinationData;
    },
    getTooltipData: function (field, examination) {
      const tooltip = JSON.parse(field.extra_options)?.tooltip;

      if (tooltip) {
        return Object.fromEntries(
          Object.entries(tooltip).map(([key, value]) => [
            key,
            examination[camelToSnakeCase(value)],
          ]),
        );
      }
    },
    canCloseCaseGeneralInfo: function (field) {
      if (field.extra_options) {
        const extraOptions = JSON.parse(field.extra_options);
        if ("close_case_general_info" in extraOptions) {
          return extraOptions.close_case_general_info;
        }
      }
      return true;
    },
    setUseActionMenu: function () {
      const { options } = this;
      this.useActionMenu =
        options && "action_menu" in options && options.action_menu;
    },
    setExaminationActions: function () {
      this.examinationActions.isPrioritized = this.examination.isPrioritized;
      this.examinationActions.isFlagged = this.examination.isFlagged;
      this.examinationActions.isBookmarked = this.examination.isBookmarked;
      this.examinationActions.isTestPatient = this.examination.isTestPatient;
    },
    onAction: function (action) {
      if (action.submitable) this.updateCaseActions(action);
      else {
        switch (action.name) {
          case PRODUCT_ACTION_NAMES.PRINT_CASE:
            this.printCase();
            break;
          case PRODUCT_ACTION_NAMES.CLOSE_CASE:
            this.closeCase();
            break;
          case PRODUCT_ACTION_NAMES.USE_UCR:
            this.submitToUcr();
            break;
        }
      }
    },
    submitToUcr: async function () {
      this.loading = true;
      try {
        const response = await practitionerHttpService.get(
          `/api/internal/tumour-wound/submit-ucr/${this.examination.id}`,
        );
        if (response.data.success)
          Notifications.saveSuccessCustomText(response.data.message);
        else Notifications.errorCustomText(response.data.message);
      } catch (error) {
        Notifications.error();
      }
      this.loading = false;
    },
    updateCaseActions: function (action) {
      // update local action values
      this.examinationActions[action.key] = this.examinationActions[action.key]
        ? false
        : true;

      this.loading = true;
      this.errorUpdatingActions = null;

      axios
        .post(
          `/api/internal/tumour-wound/actions/${this.examination.id}`,
          this.examinationActions,
        )
        .then(() => {
          Notifications.saveSuccess();
          this.loading = false;
        })
        .catch((error) => {
          this.errorUpdatingActions = true;
          this.setExaminationActions();
          this.loading = false;
          this.userErrorMessage(error);
        });
    },
    printCase: function () {
      this.showPrintDialogWithOptions = {
        data: {
          uri: `/api/internal/examinations/${this.examination.id}/print`,
          caseNo: this.examination.caseNo,
          productDefinition: this.product,
        },
      };
    },
    closePrintCase() {
      this.showPrintDialogWithOptions = null;
    },
    closeCase: function () {
      examinationCloseConfirm(this.$vueAlert.confirm, this.examination)
        .then(() => {
          this.loading = true;
          axios
            .get(`/api/internal/tumour-wound/close/${this.examination.id}`)
            .then(async () => {
              this.loading = false;

              await this.$store.dispatch(STORE_CONSTANTS.RELOAD_EXAMINATION);
            })
            .catch((error) => {
              this.loading = false;
              this.userErrorMessage(error);
            });
        })
        .catch(() => {});
    },
    getInfoPopupText: function () {
      return this.examinationData.length > 0
        ? infoPopupText(this.examinationData)
        : "";
    },
    canSeeCaseMarkings: function () {
      return (
        this.$can("prioritise_cases") &&
        this.viewOption !== "patient_overview" &&
        this.viewOption !== "study_overview"
      );
    },
    canMarkAsTestPatient: function () {
      return (
        this.$can("mark_as_test_patient") &&
        this.viewOption !== "patient_overview" &&
        this.viewOption !== "study_overview"
      );
    },
  },
};
</script>
