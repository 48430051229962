<template>
  <div class="consultation-container">
    <div v-if="loading" class="overlay">
      <clip-loader />
    </div>

    <div v-if="examination && product && !loading" id="product">
      <div
        class="tw-sticky tw-top-[--mobile-header-height] xl:-tw-top-[--main-content-padding-top] tw-bg-white tw-z-[2]"
      >
        <examinations-header
          :examination-id="examination.id"
          :product-definition="product"
          :total-examinations="totalExaminations"
          :patient="patient"
          @mount-image-compare="mountImageCompare"
          @initialize-print="printCase"
        />
      </div>

      <chat-container
        v-if="
          examination &&
          productHasPermission(PRODUCT_PERMISSIONS.USE_CONSENSUS, product)
        "
        :edit-save-uri="'/api/internal/' + examination.id + '/channels'"
        :chat-notifications="chatNotifications"
        @update-top-navigation="updateChatNotifications"
      />

      <div class="tab-content">
        <router-view
          :patient="patient"
          :examination="examination"
          :product="product"
          :primary-center-id="primaryCenterId"
        />
      </div>
    </div>
    <VueJsDialogMixinWrapper
      v-if="!!showPrintDialogWithOptions"
      :data-component="showPrintDialogComponent"
      :data-options="showPrintDialogWithOptions"
      @close="closePrintCase"
    />
  </div>
</template>
<script setup>
import VueJsDialogMixinWrapper from "../../../mixins/VueJsDialogMixinWrapper.vue";
</script>
<script>
import ImageCompare from "../AddOns/ImageCompare.vue";
import ChatContainer from "../AddOns/Chat/ChatContainer.vue";
import CustomPrintViewDialog from "../Examination/ExaminationComponents/components/PrintCaseView.vue";
import ExaminationsHeader from "./ExaminationComponents/components/ExaminationsHeader.vue";
import Examination from "./Examination.vue";
import ConsultationImages from "../AddOns/ConsultationImages.vue";
import UserErrorMessage from "../../../mixins/UserErrorMessage.vue";
import { fetchProduct, productHasPermission } from "../../../helpers/product";
import { PRODUCT_PERMISSIONS } from "../../../helpers/definitions";

export default {
  components: {
    ImageCompare,
    ExaminationsHeader,
    ConsultationImages,
    Examination,
    ChatContainer,
  },
  mixins: [UserErrorMessage],
  data() {
    return {
      loading: true,
      examination: null,
      patient: null,
      chatNotifications: null,
      product: null,
      comments: {},
      user: {},
      backButtonUri: "/",
      actions: {
        isPrioritized: false,
        isFlagged: false,
        isBookmarked: false,
      },
      images: {},
      totalExaminations: null,
      PRODUCT_PERMISSIONS: PRODUCT_PERMISSIONS,
      productHasPermission: productHasPermission,
      showPrintDialogComponent: CustomPrintViewDialog,
      showPrintDialogWithOptions: null,
    };
  },
  computed: {
    primaryCenterId() {
      return this.$store.state.user.primaryCenter.id;
    },
  },
  mounted: function () {
    this.loadData();
    this.$store.commit("setCurrentExamination", this.$route.params.id);
  },
  methods: {
    loadProduct: async function (productId) {
      const product = await fetchProduct(
        productId,
        this.examination.centerId,
        this.$store,
      );
      this.product = product;
    },
    loadData: async function () {
      const productId = await this.loadExamination();
      await this.loadProduct(productId);
      await this.getTotalExaminations();
      this.loading = false;

      localStorage.setItem(
        "last_visited_examination_id",
        this.$route.params.id,
      );
    },
    loadExamination: async function () {
      const response = await axios.get(
        `/api/internal/tumour-wound/${this.$route.params.id}`,
      );
      this.examination = response.data.examination;
      this.chatNotifications =
        response.data.notifications !== 0 ? response.data.notifications : null;

      const { age, fullName, gender, ssn } = this.examination.patient;
      const { centerId, notifications } = this.examination;

      this.patient = {
        age,
        centerId,
        fullName,
        gender,
        notifications,
        ssn,
      };

      return response.data.examination.type.id;
    },
    getTotalExaminations: async function () {
      const response = await axios.get(
        "/api/internal/image-comparer/count-examinations/" +
          this.$route.params.id +
          "?type=" +
          this.product.meta.id,
      );
      this.totalExaminations = response.data.total_examinations;
    },
    updateChatNotifications: function () {
      axios
        .get(`/api/internal/tumour-wound/${this.$route.params.id}`)
        .then((data) => {
          let collection = data.data;
          this.chatNotifications =
            collection.notifications !== 0 ? collection.notifications : null;
        });
    },
    printCase: function () {
      this.showPrintDialogWithOptions = {
        data: {
          uri: `/api/internal/examinations/${this.examination.id}/print`,
          caseNo: this.examination.caseNo,
          productDefinition: this.product,
        },
      };
    },
    closePrintCase() {
      this.showPrintDialogWithOptions = null;
    },
  },
};
</script>
