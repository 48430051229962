<template>
  <div>
    <div class="panel panel-default panel-fill">
      <div class="panel-heading">
        <h3 class="panel-title">
          {{
            center.id
              ? trans("center.editCenter")
              : trans("center.addNewCenter")
          }}
        </h3>
      </div>
      <div class="panel-body">
        <div class="row">
          <div class="m-b-20 col-md-6" :class="{ 'has-error': errors.name }">
            <label for="FullName">{{ trans("center.name") }}</label>
            <i class="far fa-asterisk" />
            <input
              id="center_name"
              v-model="center.name"
              type="text"
              name="name"
              class="form-control"
              :placeholder="trans('center.name')"
            />
            <form-error v-if="errors.name" :errors="errors.name" />
          </div>

          <div class="m-b-20 col-md-6">
            <label for="FullName">{{ trans("center.hsaId") }}</label>
            <input
              id="hsa-id"
              v-model="center.hsa_id"
              type="text"
              name="hsaid"
              class="form-control"
              :placeholder="trans('center.hsaId')"
            />
          </div>

          <div class="m-b-20 col-md-12">
            <label for="contactPerson">{{
              trans("center.contactPerson")
            }}</label>
            <input
              id="contactPerson"
              v-model="center.contact_person"
              type="text"
              name="contactPerson"
              class="form-control"
              :placeholder="trans('center.contactPerson')"
            />
          </div>

          <div class="m-b-20 col-md-12" :class="{ 'has-error': errors.email }">
            <label for="FullName">{{ trans("center.email") }}</label>
            <input
              id="email"
              v-model="center.email"
              type="email"
              name="name"
              class="form-control"
              :placeholder="trans('center.email')"
            />
            <form-error v-if="errors.email" :errors="errors.email" />
          </div>

          <div class="m-b-20 col-md-12">
            <label for="FullName">{{ trans("center.contactNo") }}</label>
            <input
              id="contact_no"
              v-model="center.contact_no"
              type="text"
              name="address"
              class="form-control"
              :placeholder="trans('center.contactNo')"
            />
          </div>

          <div class="m-b-20 col-md-12">
            <label for="FullName">{{ trans("center.fax") }}</label>
            <input
              id="fax"
              v-model="center.fax"
              type="text"
              name="fax"
              class="form-control"
              :placeholder="trans('center.fax')"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="panel panel-default panel-fill">
      <div class="panel-heading">
        <h3 class="panel-title">
          {{ trans("center.centerAddressHeader") }}
        </h3>
      </div>
      <div class="panel-body">
        <div class="row">
          <div class="m-b-20 col-md-12">
            <label for="FullName">{{ trans("center.address") }}</label>
            <input
              id="address"
              v-model="center.address"
              type="text"
              name="address"
              class="form-control"
              :placeholder="trans('center.address')"
            />
          </div>

          <div class="m-b-20 col-md-6">
            <label for="FullName">{{ trans("center.city") }}</label>
            <input
              id="city"
              v-model="center.city"
              type="text"
              name="address"
              class="form-control"
              :placeholder="trans('center.city')"
            />
          </div>

          <div class="m-b-20 col-md-6">
            <label for="FullName">{{ trans("center.postcode") }}</label>
            <input
              id="postcode"
              v-model="center.postcode"
              type="text"
              name="postcode"
              class="form-control"
              :placeholder="trans('center.postcode')"
            />
          </div>

          <div class="m-b-20 col-md-6" :class="{ 'has-error': errors.country }">
            <label for="FullName">{{ trans("center.country") }}</label>
            <i class="far fa-asterisk" />
            <country-select
              v-model="center.country"
              :country="center.country"
              top-country="SE"
              class="form-control"
            />
            <form-error v-if="errors.country" :errors="errors.country" />
          </div>

          <div class="m-b-20 col-md-6">
            <label for="FullName">{{ trans("center.province") }}</label>
            <region-select
              v-model="center.province"
              :country="center.country"
              :region="center.province"
              class="form-control"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="m-3">
      <span class="text-left"
        ><i class="far fa-asterisk" /> {{ trans("center.requiredField") }}</span
      >
    </div>
  </div>
</template>

<script>
import "vue3-country-region-select";

export default {
  props: {
    center: {
      name: String,
      hsaId: String,
      contact_person: String,
      email: String,
      contact_no: String,
      fax: String,
      adress: String,
      city: String,
      postcode: String,
      country: String,
      province: String,
    },
    errors: {},
  },
};
</script>
